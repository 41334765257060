

let mega = [
'HEALTH',
'DIGITALIZATION',
'TAXATION',
'SUSTAINABILITY',
'AUTOMATION',
'TRADE',
'POLICY'
]

export function track(type, id){

	let url = document.location.href;
	let dt = '';
	let dtxt = '';
	
	// main click on globe sector
	if(type===0){
		dt = mega[id];
		dtxt = mega[id];
	}
	// main click on sector impact button
	if(type===1){
		dt = 'SECTOR IMPACT';
		dtxt = 'IMPACT';
	}

	// mega click on more button
	if(type===2){
		dt = 'explore:' + mega[id];
		dtxt = mega[id];
	}

	// impact change select
	if(type===3){
		dt = 'explore:' + id;
		dtxt = id;
	}
	// impact click on to globe button
	if(type===4){
		dt = 'back to globe';
		dtxt = 'back to globe';
	}

	// mega click on to globe button
	if(type===5){
		dt = 'back to globe';
		dtxt = 'back to globe';
	}

	if(type===6){
		dt = 'explore:' + id;
		dtxt = id;
	}
	if(type===7){
		dt = 'explore:' + id;
		dtxt = id;
	}

	// mega click on sector impact button
	if(type===8){
		dt = 'SECTOR IMPACT';
		dtxt = 'IMPACT';
	}

	try {
		if (global.gtag){
			global.gtag('event', 'click', {
			  'event_category' : 'cta',
			  'event_label' : dt.toLowerCase()
			});
		}
	} catch (error) {
		console.log("Error from the tracker gtag=> ", error);
	}

    document.body.dispatchEvent(new CustomEvent('oTracking.event', { detail: { 
		category: 'cta',
		action: 'click',
		product: 'paid-post',
		app: 'infographic',
		url: url,
		domPathTokens: [
			{
				"data-o-event": "category:cta|action:click",
				"data-trackable": dt.toLowerCase(),
				"href": url,
				"text": dtxt.toLowerCase()
			}
		]
    }, bubbles: true}));

}