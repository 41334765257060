import React from 'react'

export const PATH = `https://ubs.alphagridinfographics.com`

export function withpath(name){
	return PATH + name;
}


export const COLORS = [`#00B359`,`#FF7FAA`,`#FF1A66`,`#1ABCFF`,`#008A98`,`#86b22d`,`#CC0000`];
export const STRINGNUMBERS = ["seven", "six", "five", "four", "three", "two", "one"];

export const ICONS = [
`
	<g transform="translate(-.067 -.257)">
		<g fill="none" stroke="#00b359" strokeLinecap="round" strokeLinejoin="round">
			<path d="M76.947 47.712a.8.8 0 00-.8-.8h-3.975v-3.97a.8.8 0 00-.8-.8h-3.179a.8.8 0 00-.8.8v3.979h-3.97a.8.8 0 00-.8.8v3.183a.8.8 0 00.8.8h3.979v3.979a.8.8 0 00.8.8h3.183a.8.8 0 00.8-.8v-3.992h3.979a.8.8 0 00.8-.8z"/>
			<path d="M56.257 45.325v17.507a1.592 1.592 0 001.592 1.593h23.872a1.592 1.592 0 001.592-1.592V45.325M51.482 44.529l18.3-16.711 18.3 16.711"/>
		</g>
	</g>
`,
`
	<g fill="none" stroke="#ff7faa" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
		<path d="M63.715 49.035h12.6a3.655 3.655 0 002.2-.739l8.186-6.59a3.055 3.055 0 015.194 2.094c0 1.352-1.093 2.29-2.053 3.141l-9.42 7.329a4.254 4.254 0 01-3.141 1.048H64.714a3.244 3.244 0 00-2.094 1.047l-2.56 2.56"/>
		<path d="M74.771 49.034a3.142 3.142 0 000-6.283H60.554a6.311 6.311 0 00-4.188 2.094l-5.192 5.192"/>
		<path d="M45.944 51.074l3.133-3.133 13.577 13.577-3.133 3.133"/>
		<path d="M62.638 42.753V22.828h6.282"/>
		<path d="M68.92 42.752V18.699h6.283v8.437"/>
		<path d="M75.203 42.781V27.135h6.283v14.54"/>
	</g>
`,
`
	<g transform="translate(50.633 24.748)" fill="none" stroke="#ff1a66" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
		<ellipse cx="17.159" cy="4.576" rx="17.159" ry="4.576" transform="translate(.001 16.773)"/>
		<path d="M34.318 21.348v6.864c0 2.528-7.68 4.576-17.159 4.576S0 30.74 0 28.212v-6.864"/>
		<path d="M34.318 28.211v6.864c0 2.528-7.68 4.576-17.159 4.576S0 37.603 0 35.075v-6.864"/>
		<path d="M22.652.568l-10.66 10.66"/>
		<path d="M13.727 0a1.144 1.144 0 11-1.144 1.144A1.143 1.143 0 0113.727 0"/>
		<path d="M20.591 9.151a1.144 1.144 0 11-1.144 1.144 1.143 1.143 0 011.144-1.144"/>
	</g>
`,
`
	<g transform="translate(-.395 .357)">
		<g transform="translate(37.395 6.745)" fill="none" stroke="#1abcff" strokeLinecap="round" strokeLinejoin="round">
			<path d="M31.164 32.157l-2.909-3.845 1.87-20.927c.2-3.756 3.343-8.128 3.315-7.278l-.944 32.09M34.738 37.474l4.79-.544 17.053 12.273c3.129 2.088 5.29 7.018 4.573 6.561L34.027 38.598M28.891 37.784l-2.184 4.3-19.635 7.475c-3.466 1.462-8.772.55-7.994.2l29.268-13.191"/>
			<circle cx="3.346" cy="3.346" r="3.346" transform="translate(28.569 32.149)"/>
			<path d="M38.606 22.013a15.055 15.055 0 018.365 13.49"/>
			<path d="M25.223 22.013a15.056 15.056 0 00-8.364 13.49"/>
			<path d="M39.851 48.292a15.056 15.056 0 01-15.872 0"/>
		</g>
	</g>
`,
`
	<g transform="translate(-725 -240.542)">
		<g transform="translate(770.938 252.76)">
		  <path d="M963.074,496.7a8.2,8.2,0,1,1,16.377,0" transform="translate(-946.23 -450.237)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <circle cx="2.707" cy="2.707" r="2.707" transform="translate(8.27 0.449)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <circle cx="2.707" cy="2.707" r="2.707" transform="translate(30.471 7.696)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <rect width="38.614" height="6.254" rx="3.127" transform="translate(44.339 52.832) rotate(180)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <line x1="7.403" y2="25.137" transform="translate(25.123 12.493)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <line x1="16.59" y1="6.187" transform="translate(13.646 3.733)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <g transform="translate(0 5.871)">
		    <circle cx="1.552" cy="1.552" r="1.552" transform="translate(9.601 5.37)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    <line x1="0.046" y1="5.576" transform="translate(11.096)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    <g transform="translate(12.79 7.451)">
		      <circle cx="1.552" cy="1.552" r="1.552" transform="translate(6.471 6.34)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line x1="7.129" y1="7.012" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line x1="4.555" y2="4.631" transform="translate(2.4 9.269)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    </g>
		    <g transform="translate(0 7.479)">
		      <circle cx="1.552" cy="1.552" r="1.552" transform="translate(0 6.471)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line y1="7.129" x2="7.012" transform="translate(2.432)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line x2="4.631" y2="4.555" transform="translate(2.643 9.383)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    </g>
		  </g>
		  <rect width="7.809" height="7.206" rx="1.411" transform="translate(7.3 24.285)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <path d="M978.243,452.667a4.545,4.545,0,0,1,6.979,1.721" transform="translate(-947.365 -447.509)" fill="none" stroke="#008a98" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		  <path d="M976.154,448.854a8.048,8.048,0,0,1,12.245,3.027" transform="translate(-947.208 -447.171)" fill="none" stroke="#008a98" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</g>
`,
`
	<g transform="translate(-379.967 -140.508)" fill="none" stroke="#86b22d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
	  <path d="M468.735 193.376l-13.64 2.728"/>
	  <path d="M456.584 180.694l-6.44 2.643a4.166 4.166 0 01-5.381-2.4 4.165 4.165 0 011.976-5.192l6.431-3.125a5.744 5.744 0 014.541-.26l11.744 4.492"/>
	  <path d="M430.548 196.32h4.859l8.088 6.148a2.374 2.374 0 003.277.257l12.149-9.743a2.376 2.376 0 00.365-3.3l-6.673-7.353"/>
	  <path d="M446.205 176.099l-.869-.716a5.11 5.11 0 00-3.02-.989 5.036 5.036 0 00-1.892.365l-9.876 4.2"/>
	  <path d="M420.133 199.792h6.943a3.474 3.474 0 003.472-3.472v-17.357a3.473 3.473 0 00-3.472-3.468h-6.943z"/>
	  <path d="M479.148 199.792h-6.943a3.474 3.474 0 01-3.472-3.472v-17.357a3.473 3.473 0 013.472-3.468h6.943z"/>
	</g>
`,
`
	<g fill="none" stroke="#c00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
		<path d="M85.408 57.061a1.722 1.722 0 01-1.722 1.721H57.853a1.722 1.722 0 01-1.722-1.721V20.885a1.724 1.724 0 011.722-1.722h16.52a1.73 1.73 0 011.223.5l9.323 9.325a1.717 1.717 0 01.5 1.213z"/>
		<path d="M75.074 19.322v8.457a1.725 1.725 0 001.719 1.727h8.46"/>
		<path d="M61.446 36.389h18.801"/>
		<path d="M61.446 31.223h8.584"/>
		<path d="M61.446 26.056h5.167"/>
		<path d="M61.446 41.556h18.801"/>
		<path d="M61.094 47.415h18.801"/>
		<path d="M61.094 52.582h18.801"/>
	</g>
`
];


const colorNonComplete = `#242830`

const nonCompleteTxt = 
`
	<text transform="translate(39 110.458)" fill="#fff" font-size="12" font-family="Metric-Medium, Metric" font-weight="500" letter-spacing=".019em" style="isolation:isolate">
		<tspan x="0" y="0">Click to view</tspan>
	</text>
`
const completeTxt = 
`
	<path data-name="Path 1458" d="M53.749 95.458H36.636A2.643 2.643 0 0034 98.095v16.081a2.645 2.645 0 002.636 2.638H53.75a2.646 2.646 0 002.637-2.638V98.095a2.644 2.644 0 00-2.638-2.637zm-1.857 7.723l-8.681 8.68-4.258-4.26a1.277 1.277 0 010-1.8 1.275 1.275 0 011.8 0l2.453 2.453 6.873-6.873a1.277 1.277 0 011.805 0 1.277 1.277 0 01.012 1.8z" fill="#fff"/>
	<text data-name="Complete" transform="translate(60.406 110.458)" fill="#fff" font-size="12" font-family="Metric-Medium, Metric" font-weight="500" letter-spacing=".019em" style="isolation:isolate">
		<tspan x="0" y="0">Complete</tspan>
	</text>
`


export const ICONSMORE = [

	<g fill="none" stroke="#00b359" strokeLinecap="round" strokeLinejoin="round">
		<path d="M76.947 47.712a.8.8 0 00-.8-.8h-3.975v-3.97a.8.8 0 00-.8-.8h-3.179a.8.8 0 00-.8.8v3.979h-3.97a.8.8 0 00-.8.8v3.183a.8.8 0 00.8.8h3.979v3.979a.8.8 0 00.8.8h3.183a.8.8 0 00.8-.8v-3.992h3.979a.8.8 0 00.8-.8z"/>
		<path d="M56.257 45.325v17.507a1.592 1.592 0 001.592 1.593h23.872a1.592 1.592 0 001.592-1.592V45.325M51.482 44.529l18.3-16.711 18.3 16.711"/>
	</g>
	,
	<g fill="none" stroke="#ff7faa" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
		<path d="M63.715 49.035h12.6a3.655 3.655 0 002.2-.739l8.186-6.59a3.055 3.055 0 015.194 2.094c0 1.352-1.093 2.29-2.053 3.141l-9.42 7.329a4.254 4.254 0 01-3.141 1.048H64.714a3.244 3.244 0 00-2.094 1.047l-2.56 2.56"/>
		<path d="M74.771 49.034a3.142 3.142 0 000-6.283H60.554a6.311 6.311 0 00-4.188 2.094l-5.192 5.192"/>
		<path d="M45.944 51.074l3.133-3.133 13.577 13.577-3.133 3.133"/>
		<path d="M62.638 42.753V22.828h6.282"/>
		<path d="M68.92 42.752V18.699h6.283v8.437"/>
		<path d="M75.203 42.781V27.135h6.283v14.54"/>
	</g>
	,
	<g transform="translate(50.633 24.748)" fill="none" stroke="#ff1a66" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
		<ellipse cx="17.159" cy="4.576" rx="17.159" ry="4.576" transform="translate(.001 16.773)"/>
		<path d="M34.318 21.348v6.864c0 2.528-7.68 4.576-17.159 4.576S0 30.74 0 28.212v-6.864"/>
		<path d="M34.318 28.211v6.864c0 2.528-7.68 4.576-17.159 4.576S0 37.603 0 35.075v-6.864"/>
		<path d="M22.652.568l-10.66 10.66"/>
		<path d="M13.727 0a1.144 1.144 0 11-1.144 1.144A1.143 1.143 0 0113.727 0"/>
		<path d="M20.591 9.151a1.144 1.144 0 11-1.144 1.144 1.143 1.143 0 011.144-1.144"/>
	</g>
,
	<g transform="translate(37.395 6.745)" fill="none" stroke="#1abcff" strokeLinecap="round" strokeLinejoin="round">
		<path data-name="Path 7297" d="M31.164 32.157l-2.909-3.845 1.87-20.927c.2-3.756 3.343-8.128 3.315-7.278l-.944 32.09M34.738 37.474l4.79-.544 17.053 12.273c3.129 2.088 5.29 7.018 4.573 6.561L34.027 38.598M28.891 37.784l-2.184 4.3-19.635 7.475c-3.466 1.462-8.772.55-7.994.2l29.268-13.191"/>
		<circle cx="3.346" cy="3.346" r="3.346" transform="translate(28.569 32.149)"/>
		<path d="M38.606 22.013a15.055 15.055 0 018.365 13.49"/>
		<path d="M25.223 22.013a15.056 15.056 0 00-8.364 13.49"/>
		<path d="M39.851 48.292a15.056 15.056 0 01-15.872 0"/>
	</g>
,
	<g transform="translate(-725 -240.542)">
		<g transform="translate(770.938 252.76)">
		  <path d="M963.074,496.7a8.2,8.2,0,1,1,16.377,0" transform="translate(-946.23 -450.237)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <circle cx="2.707" cy="2.707" r="2.707" transform="translate(8.27 0.449)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <circle cx="2.707" cy="2.707" r="2.707" transform="translate(30.471 7.696)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <rect width="38.614" height="6.254" rx="3.127" transform="translate(44.339 52.832) rotate(180)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <line x1="7.403" y2="25.137" transform="translate(25.123 12.493)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <line x1="16.59" y1="6.187" transform="translate(13.646 3.733)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <g transform="translate(0 5.871)">
		    <circle cx="1.552" cy="1.552" r="1.552" transform="translate(9.601 5.37)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    <line x1="0.046" y1="5.576" transform="translate(11.096)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    <g transform="translate(12.79 7.451)">
		      <circle cx="1.552" cy="1.552" r="1.552" transform="translate(6.471 6.34)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line x1="7.129" y1="7.012" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line x1="4.555" y2="4.631" transform="translate(2.4 9.269)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    </g>
		    <g transform="translate(0 7.479)">
		      <circle cx="1.552" cy="1.552" r="1.552" transform="translate(0 6.471)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line y1="7.129" x2="7.012" transform="translate(2.432)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		      <line x2="4.631" y2="4.555" transform="translate(2.643 9.383)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		    </g>
		  </g>
		  <rect width="7.809" height="7.206" rx="1.411" transform="translate(7.3 24.285)" fill="none" stroke="#008a98" strokeMiterlimit="10" strokeWidth="1.5"/>
		  <path d="M978.243,452.667a4.545,4.545,0,0,1,6.979,1.721" transform="translate(-947.365 -447.509)" fill="none" stroke="#008a98" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		  <path d="M976.154,448.854a8.048,8.048,0,0,1,12.245,3.027" transform="translate(-947.208 -447.171)" fill="none" stroke="#008a98" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</g>
,

	<g transform="translate(-379.967 -140.508)" fill="none" stroke="#86b22d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
	  <path d="M468.735 193.376l-13.64 2.728"/>
	  <path d="M456.584 180.694l-6.44 2.643a4.166 4.166 0 01-5.381-2.4 4.165 4.165 0 011.976-5.192l6.431-3.125a5.744 5.744 0 014.541-.26l11.744 4.492"/>
	  <path d="M430.548 196.32h4.859l8.088 6.148a2.374 2.374 0 003.277.257l12.149-9.743a2.376 2.376 0 00.365-3.3l-6.673-7.353"/>
	  <path d="M446.205 176.099l-.869-.716a5.11 5.11 0 00-3.02-.989 5.036 5.036 0 00-1.892.365l-9.876 4.2"/>
	  <path d="M420.133 199.792h6.943a3.474 3.474 0 003.472-3.472v-17.357a3.473 3.473 0 00-3.472-3.468h-6.943z"/>
	  <path d="M479.148 199.792h-6.943a3.474 3.474 0 01-3.472-3.472v-17.357a3.473 3.473 0 013.472-3.468h6.943z"/>
	</g>
,

	<g fill="none" stroke="#c00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
		<path d="M85.408 57.061a1.722 1.722 0 01-1.722 1.721H57.853a1.722 1.722 0 01-1.722-1.721V20.885a1.724 1.724 0 011.722-1.722h16.52a1.73 1.73 0 011.223.5l9.323 9.325a1.717 1.717 0 01.5 1.213z"/>
		<path d="M75.074 19.322v8.457a1.725 1.725 0 001.719 1.727h8.46"/>
		<path d="M61.446 36.389h18.801"/>
		<path d="M61.446 31.223h8.584"/>
		<path d="M61.446 26.056h5.167"/>
		<path d="M61.446 41.556h18.801"/>
		<path d="M61.094 47.415h18.801"/>
		<path d="M61.094 52.582h18.801"/>
	</g>

]





export function icon(i, name, color, complete) {

	return `<svg xmlns="http://www.w3.org/2000/svg" width="165" height="165">
			
				<g transform="translate(10 10)">

					<path fill="${(complete)?color:colorNonComplete}" opacity=".45" d="M70.191.144c38.552 0 69.8 31.653 69.8 70.7s-31.253 70.7-69.8 70.7-69.8-31.654-69.8-70.7 31.253-70.7 69.8-70.7z"/>
					
					<text transform="translate(68.646 83.289)" fill="#fff" font-size="18" font-family="Metric-Light, Metric" font-weight="300" text-anchor="middle">
						<tspan x="0" y="0">${name}</tspan>
					</text>

					<g fill="none" stroke="${color}">
						<ellipse cx="69.953" cy="70.619" rx="69.953" ry="70.619" stroke="none"/>
						<ellipse cx="69.953" cy="70.619" rx="69.453" ry="70.119"/>
					</g>

					${ICONS[i]}

					${(complete)?completeTxt:nonCompleteTxt}

				</g>


				<g class="ubs-icon-hover">
				    <g transform="translate(-370 -312.5)">
					    <g transform="translate(381 323.542)" fill="none" stroke="${color}" stroke-width="12" opacity="0.538">
					      <ellipse cx="68.916" cy="69.955" rx="68.916" ry="69.955" stroke="none"/>
					      <ellipse cx="68.916" cy="69.955" rx="74.916" ry="75.955" fill="none"/>
					    </g>
					    <g transform="translate(381 323.542)" fill="none" stroke="${color}" stroke-width="6" opacity="0.538">
					      <ellipse cx="68.916" cy="69.955" rx="68.916" ry="69.955" stroke="none"/>
					      <ellipse cx="68.916" cy="69.955" rx="71.916" ry="72.955" fill="none"/>
					    </g>
				    <g>
				<g>

			</svg>`

}